$c-primary: #d3133e;
$c-secandary: #d3133e;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Georgia', sans-serif;
$font-family-heading: 'Georgia', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -12px;
    border: none;
    background: none;
    display: block;
  }

  .transparentButton {
    border: none;
    background: none;
    display: block;
    width: 100%;
  }
}
