$c-primary: #d3133e;
$c-secandary: #d3133e;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Georgia', sans-serif;
$font-family-heading: 'Georgia', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;
  overflow-y: auto;

  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }

  .link_list {
    margin: 0 1.5rem;
  }
  .link {
    width: 100% !important;
    height: auto;
    padding: 1rem 0 0.3125rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -0.01875rem; //-0.332143px;
    span {
      white-space: pre-line;
      text-align: left;
    }
    img {
      width: auto;
      height: 2rem; //32
      margin-left: 0.5rem;
    }
  }
  .accepted {
    margin-left: 1.5rem;
    div[role='list'] {
      margin-top: 1rem;
      div[role='listitem'] {
        margin-bottom: 0.5rem;
      }
    }
  }
  .listAndroid {
    display: list-item;
    list-style: disc;
    span {
      display: none;
    }
  }
}
