$c-primary: #d3133e;
$c-secandary: #d3133e;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Georgia', sans-serif;
$font-family-heading: 'Georgia', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.content {
  word-break: break-word;

  a {
    font-style: italic;
  }
}
